


.form-control {
  &::placeholder {
    font-weight: 600;
    color: $gray;
  }
}
.smart-input {
  width: 100%;
}

.icon-input {
  position: relative;
  &.left-icon {
    input {
      padding-left: 2.5rem !important;
    }
    .icon {
      left: 1rem;
    }
  }
  &.right-icon {
    input {
      padding-right: 2.5rem !important;
    }
    .icon {
      right: 1rem;
    }
  }
  .icon {
    transition: 400ms;
    position: absolute;
    font-size: 18px;
    color: $gray-700;
    top: 50%;
    transform: translateY(-50%);
  }
}

.css-yk16xz-control, .css-1pahdxg-control {
  border-radius: $border-radius !important;
  border: 1px solid $gray-300 !important;
  min-height: $input-height !important;
  // Tags
  .css-1rhbuit-multiValue {
    border-radius: $border-radius !important;
    margin: 0.25rem;
    background-color: $gray-200;
    .css-12jo7m5 {
      color: $dark;
      padding: 0.33rem 0.75rem;
    }
  }
}
.css-1pahdxg-control {
  border: 1px solid $gray !important;
  box-shadow: none !important;
}

.address-input {
  @extend .row-flex;
  i {
    font-size: 1.5rem;
  }
  .address {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .city {

  }
}

.main-search {
  position: relative;
  i {
    color: $gray;
  }
  .icon-input {
    max-width: 500px;
  }
  // select {
  //   width: 100%;
  //   height: 60px;
  //   padding: 0.75rem 1rem 0.75rem 1rem;
  //   border-radius: $border-radius;
  //   outline: 0;
  //   border: 0;
  //   box-shadow: 0 3px 25px 0 rgba(62, 69, 80, 0.12);
  //   background-color: white;
  //   color: $gray;
  // }
}

.fake-input {
  cursor: text;
  padding: 0.5rem 0;
  @extend .border-bottom;
  color: $gray;
  width: 100%;
}

.light-input {
  border: 0;
  border-bottom: 1px solid transparent;
  outline: none;
  padding: 0.5rem 0;
  width: 100%;
  &:hover {
    @extend .border-bottom;
  }
  &:focus, &:focus:hover {
    transition: 200ms;
    border-bottom: 1px solid $primary;
  }
}

.list-inputs-item {
  .grip-vertical {
    // opacity: 0;
    color: $gray;
  }
  &:hover .grip-vertical {
    // opacity: 1;
  }
}

.quantity-input {
  outline: 0;
  width: 52px;
  border: 1px solid $gray-200;
}

.media-file {
  position: absolute;
  opacity: 0;
  width: 0;
}

.select-design {
  position: relative;
  .icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    color: $gray;
    font-size: 16px;
    transform: translateY(-50%);
  }
  input {
    padding-left: 3rem;
    height: 100%;
    border-radius: $border-radius;
    border: 0 !important;
    &:focus {
      border: 0 !important;
    }
  }
  .select-button {
    position: relative;
    width: 100%;
    height: $input-height;
    transition: 400ms;
    cursor: pointer;
    display: flex;
    @extend .border;
    border-radius: $border-radius;
    .selected-option {
      position: relative;
      width: 100%;
      padding: 0 1.5rem 0 0.5rem;
    }
    .add-btn-select {
      width: 40px;
      min-width: 40px;
      text-align: center;
      border-radius: 0.5rem;
      margin: 4px;
      line-height: 40px;
    }
    &:hover {
      background-color: $gray-100;
    }
    &.open:hover {
      background-color: white;
    }
    .caret {
      position: absolute;
      top: 50%;
      color: $gray;
      right: 0.5rem;
      transform: translateY(-50%);
      font-size: 1.1rem;
    }
    .select-icon {
      width: 2rem;
      height: 2rem;
      background-color: $primary;
      display: flex;
      border-radius: $border-radius;
      margin-left: -0.5rem;
      color: white;
      font-size: 1.1rem;
      &-blue {
        @extend .select-icon;
        background-color: $primary;
      }
      svg {
        margin: auto;
      }
    }
    .select-title {
      font-weight: 600;
      margin: auto;
      color: $gray-400;
      padding: 0 1rem;
    }
    .selected-value {
      color: $gray;
      font-size: 0.8rem;
    }
  }
  .select-options {
    box-shadow: $shadow;
    border-radius: $border-radius;
    padding: 0.5rem 0;
    position: absolute;
    z-index: 10;
    background: white;
    width: 100%;
    .option {
      cursor: pointer;
      padding: 0.5rem 1rem;
      font-weight: 600;
      color: $dark;
      &.selected {
        color: $primary;
      }
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}

.is-focus {
  transition: 400ms;
  color: $primary;
}

.integer-input {
  display: flex;
  align-items: center;
  max-width: 120px;
  overflow: hidden;
  border-radius: $border-radius;
  border: $input-border-width solid $gray-200;
  input {
    text-align: center;
    height: calc(#{$input-height} - 4px);
    width: 100%;
    border-top: 0;
    outline: 0;
    border-bottom: 0;
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }
  .integer-button {
    transition: 200ms;
    outline: 0;
    width: 60px;
    color: $gray-200;
    cursor: auto;
    height: calc(#{$input-height} - 4px);
    background-color: white;
    border: 0;
    &.active {
      color: $gray-600;
      cursor: pointer;
      &:hover {
        background-color: $gray-200;
      }
    }
  }
}

.home-address-select {
  .address-select input, .select-design .select-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: white;
    border: 1px solid $gray-300 !important;
  }
  .btn.btn-primary {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.ghost-input {
  opacity: 0;
  width: 0;
  height: 0;
}

// CHECKBOX
.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  i {
    line-height: 13px;
    font-size: 0.8rem;
    color: white;
  }
}
.cbx:not(:last-child) {
  margin-right: 6px;
}
.cbx:hover {
  background: rgba(0,119,255,0.06);
}
.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0,16,75,0.05);
}
.cbx span:first-child i {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.cbx:hover span:first-child {
  border-color: $primary;
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: $primary;
  border-color: $primary;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child i {
  stroke-dashoffset: 0;
}
.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

// RANGE INPUT

@mixin rangeThumb {
  width: 18px;
  height: 18px;
  margin: -8px 0  0;
  border-radius: $rounded-pill;;
  background: $primary;
  cursor: pointer;
  border: 0 !important;
}

@mixin rangeTrack {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: $gray-300;
}

.range {
  position: relative;
  height: 5px;
  width: 100%;
}

.range input {
  width: 100%;
  position: absolute;
  top: 2px;
  height: 0;
  -webkit-appearance: none;

  // Thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none; // needed again for Chrome & Safari
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  // Track
  &::-webkit-slider-runnable-track {
    @include rangeTrack;
  }

  &::-moz-range-track {
    @include rangeTrack;
  }

  &::-ms-track {
    @include rangeTrack;
  }

  &:focus { // override outline/background on focus
    background: none;
    outline: none;
  }

  &::-ms-track { // A little somethin' somethin' for IE
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

// Labels below slider
.range-labels {
  margin: 0 -11px;
  div {
    position: relative;
    margin-top: 14px;
    width: 30px;
    text-align: center;
    color: $gray;
    &::before {
      position: absolute;
      top: -21px;
      right: 0;
      left: 0;
      content: "";
      margin: 0 auto;
      width: 9px;
      height: 9px;
      background: $gray-300;
      border-radius: $rounded-pill;;
    }
  }

  .active {
    color: $primary;
  }

  .selected::before {
    background: $primary;
  }

  .active.selected::before {
    display: none;
  }
}



.onoffswitch {
  position: relative;
  width: 55px;
  display: inline-block;
  font-size: 80%;
}
.onoffswitch .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 90%);
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  margin: 0;
}
.onoffswitch .onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.15s ease-in-out;
  -o-transition: margin 0.15s ease-in-out;
  -moz-transition: margin 0.15s ease-in-out;
  transition: margin 0.15s ease-in-out;
}
.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 24px;
  padding: 0;
  line-height: 24px;
  font-size: 80%;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch .onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: hsl(203, 100%, 39.1569%);
  color: hsl(0, 0%, 100%);
}
.onoffswitch .onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: hsl(0, 0%, 98%);
  color: hsl(0, 0%, 24%);
  text-align: right;
}
.onoffswitch .onoffswitch-switch {
  width: 22px;
  height: 22px;
  margin: 0;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: $rounded-pill;;
  -webkit-border-radius: $rounded-pill;;
  border-radius: $rounded-pill;;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 35px;
  -webkit-transition: right 0.15s ease-in-out;
  -o-transition: right 0.15s ease-in-out;
  -moz-transition: right 0.15s ease-in-out;
  transition: right 0.15s ease-in-out;
}
.toggle-group {
  position: relative;
}
.toggle-group input[type=checkbox] {
  position: absolute;
  left: 10px;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
  right: 1px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.toggle-group input[type=checkbox]:focus ~ .onoffswitch {
  outline: thin dotted #333;
  outline: 0;
}
.toggle-group label {
  position: absolute;
  cursor: pointer;
  padding-left: 65px;
  display: inline-block;
  text-align: left;
  line-height: 24px;
  width: 100%;
  z-index: 1;
  height: 24px;
  font-weight: 200;
}
/* ==== Accessibility ===== */
.aural {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.aural:focus {
  clip: rect(0, 0, 0, 0);
  font-size: 1em;
  height: auto;
  outline: thin dotted;
  position: static !important;
  width: auto;
  overflow: visible;
}