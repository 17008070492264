
// $body-bg: #f8fafc;
$navbar-height: 80px;
// Typography
$font-family-sans-serif: 'Custom', sans-serif;
$font-size-base: 0.9rem;

// Neutrals
$medium-gray: #C0D6DF;
$medium-gray2: #b2b3bd;

$gray-100: #f4f7fa;
$gray-200: #ebf0f4;
$gray-300: #CACFD5;
$gray-400: #A8AEB5;
$gray: #8E959D;
$gray-600: #757D86;
$gray-700: #5B646E;
$gray-800: #414B57;
$gray-900: #242028;
$dark: $gray-900;

// Colors

$primary:       #6aba7b;
$primarydark:   #529050;
$primarylight:  #a6d8c4;

$secondary:     #7e8e8e;
$success:       #6aba7b;
$info:          #e44f33;
$infodark:      #a93822;
$infolight:     #e6635d;
$warning:       #FAA916;
$danger:        #e44f33;

$google-red: #e44f3a;
$facebook: #4267b2;

$sidebar-color: $dark;

$shadow: 0 3px 25px 0 rgba(62, 69, 80, 0.12);
$shadow2: 0 3px 25px 0 rgba(62, 69, 80, 0.12), 0 2px 5px 0 rgb(62 69 80 / 10%);
$little-shadow: 0 2px 5px 0 rgba(62, 69, 80, 0.12);
$line-height-base: 1.6;

$height-element: 50px;
$input-height: $height-element;
// $btn-line-height: $height-element;

$sidebar-width: 260px;
$admin-navbar-height: 65px;
$banner-height: 350px;
$main-action-bar-height: 100px;

$border-radius: 0;
$border-radius-lg: 3rem;
$border-radius-sm: 0;
$modal-content-border-radius: 0;

$enable-shadows: true;
// $enable-gradients: true;

// Layout
$body-bg: white;
$body-color: $gray-800;
$grid-gutter-width: 2rem;


// Tabs
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 3px;
$nav-tabs-link-active-border-color: $primary;

// Dropdown
$dropdown-spacer:            1rem;
$dropdown-border-width:      0;
$dropdown-box-shadow:        0 .5rem 1rem rgba($dark, .175);
$dropdown-link-active-color: white;
$dropdown-link-active-bg:    $primary;
$dropdown-item-padding-y:    .5rem !default;
$dropdown-item-padding-x:    1.75rem !default;

// Button
$btn-padding-x: 1rem;
$btn-padding-y: calc(0.5rem - 2px);
$btn-padding-y-sm: 0.375rem;
$btn-padding-x-sm: 0.5rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;
$btn-border-width: 2px;
$btn-font-weight: 600;
$btn-box-shadow: none;

// Input
$input-color: $dark;
$input-border-width: 2px;
$input-border-color: $gray-200;
$input-bg: white;
$input-box-shadow: none;
$input-padding-y: 0.375rem;
$input-padding-x: 1.25rem;

$input-focus-bg: white;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;

// Card
$card-border-width: 0;
$card-cap-bg: transparent;

// Modal
$modal-content-border-width: 0;
$modal-header-padding: 1rem 1rem 0 1rem;
$modal-inner-padding: 1.5rem;

$modal-fade-transform:              scale(0.5);
$modal-transition:                  transform .2s ease-in-out;


// Table {
$table-hover-bg: $gray-100;