
a:hover {
  text-decoration: none;
}
.pagination {
  margin-bottom: 0;
}
.logo {
  width: 200px;
}
.announces {
  height: 300px;
  display: flex;
  .announce {
    height: 80%;
    margin: auto;
  }
}

.bg-image {
  background-position: center;
  background-size: cover;
}

.home-background {
  @extend .gradient-container;
  // background-position: center;
  // background-size: cover;
  // background-image: url("../../img/banner.jpg");
  height: 100vh;
}

.distance {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  background-color: white;
  border-radius: $border-radius 0 $border-radius 0;
  font-size: 0.8rem;
  width: fit-content;
}
.corner {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  background-color: #e4514c;
  color: white;
  border-radius: $border-radius 0 $border-radius 0;
  font-size: 0.8rem;
  font-weight: 600;
  position: absolute;
  width: fit-content;
  right: 0;
  bottom: 0;
}

.responsive-filter {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: white;
  box-shadow: $shadow;
  select {
    box-shadow: none;
  }
  i {
    font-size: 1.25rem;
  }
}
.page-link {
  line-height: 1;
  cursor: pointer;
}

.small-shadow {
  box-shadow: 0px 4px 5px -2px #3240501c;;
}

.step-delivery-line {
  background-color: $gray-200;
  border-radius: $rounded-pill;;
  margin: 0 11%;
  .fill {
    background-color: $success;
    border-radius: $rounded-pill;;
    text-align: right;
    i {
      color: white;
      font-size: 1.25rem;
    }
  }
}

.mention {
  font-size: 1.125rem;
}

.color-indice {
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
  &.selected {
    border: 1px solid $gray-400;
  }
  .color-indice-core {
    border-radius: 30px;
    width: 28px;
    height: 28px;
  }
  .color-indice-input {
    border-radius: 30px;
    width: 34px;
    height: 34px;
  }
}

.center-loading {
  margin: 140px 0;
  text-align: center;
}