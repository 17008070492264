.bottom-navbar {
  border-top: 1px solid $gray-200;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 7px -2px rgb(49 52 70 / 10%);
  background-color: white;
  .bottom-navbar-link {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    transition: 300ms;
    .fas {
      font-size: 1.20rem;
    }
    &.active {
      background-color: $primary;
      font-weight: bold;
      color: white;
      .fas {
        font-size: 1.35rem;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .bottom-navbar .bottom-navbar-link {
    padding: 1rem;
  }
}

.navbar {
  background-color: white;
  width: 100%;
  border-bottom: 1px solid $gray-200;
  z-index: 10;
  .navbar-container {
    @extend .row-between;
    @extend .container-fluid;
    height: $navbar-height;
    & > * {
      flex: 1;
    }
  }
}

.responsive-panel {
  animation: menu 400ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  .logo {
    width: 150px;
  }
  a {
    color: $primary;
  }
  .nav-item {
    padding: 0.5rem 1.5rem;
    border-radius: 0;
    font-size: 1.25rem;
    a {
      color: $dark;
    }
    &.active {
      background-color: $primary;
      a {
        color: white;
      }
    }
  }
  .responsive-menu-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;
    font-size: 1.25rem;
    color: $gray;
  }
}
.responsive-menu {
  padding: 0.5rem;
}
