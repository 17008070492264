.base-toaster {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
}

.toast {
  border: 0;
  .close-btn {
    cursor: pointer;
    padding: 0 0.5rem;
  }
}

.toast-header {
  background-color: white;
  border: 0;
  padding-top: 0.5rem;
  color: $primary;
}
.toast-body {
  background-color: white;
}
.toast-success {
  .toast-body {
    // color: $success;
  }
}