
.pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.flex-100 {
  flex: 1 1 100%;
}

.row-flex {
  display: flex;
  align-items: center;
}

.row-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.awesome-shadow {
  background-color: white;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 5px;
    right: 5px;
    bottom: 0px;
    z-index: -2;
    background: #e0e8f0;
    opacity: 0.91;
    filter: blur(20px);
    -webkit-filter: blur(20px);
    border-radius: 24px;
  }
}