


.table {
  thead {
    color: $medium-gray2;
    th {
      border: 0;
      padding: 1.25rem 1rem;
      font-size: 0.8rem;
    }

  }
  tbody {
    tr {
      td {
        padding: 1.5rem 1rem;
      }
    }
  }
}
.table-hover tbody tr {
  cursor: pointer;
}