

.modal-header {
  font-weight: 600;
}
.close-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: $rounded-pill;
  cursor: pointer;
  text-align: center;
  color: $gray;
  font-size: 1.15rem;
  &:hover {
    background-color: $gray-200;
  }
}

.base-dialog {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  .dialog-body {
    animation: backInUp 600ms ease;
    margin: auto;
    background-color: #27323f;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: $border-radius $border-radius 0 0;
    &.closing {
      animation: backOutDown 600ms ease;
    }
  }
}

.alert-dialog {
  padding: 1.5rem;
  .alert-dialog-header {
    @extend .row-center;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .alert-dialog-body {
    @extend .row-center;
    text-align: center;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;

  }
  .alert-dialog-footer {
    @extend .row-center;
  }
}