
@font-face {
  font-family: "Custom";
  font-weight: 400;
  src: url('../../font/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Custom";
  font-weight: 600;
  src: url('../../font/Inter-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Custom";
  font-weight: 800;
  src: url('../../font/Inter-Bold.ttf') format('truetype');
}

.bg-light {
  background-color: $gray-100;
}

.heading-text {
  font-size: 1.1rem;
}
.small-text {
  font-size: 0.9rem;
}
.smaller-text {
  font-size: 0.8rem;
}

.form-title {
  font-size: 1rem;
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

label {
  color: $gray-600;
}

.text-secondary {
  color: $gray-200;
  @extend .small-text;
}
.medium-text {
  color: $gray-200;
}

.large-icon {
  font-size: 1.25rem;
}