

.users-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1.5rem;
}
.user-card {
  display: flex;
  padding: 1rem;
  border: 1px solid $gray-200;
  > * {
    flex: 1;
  }
}