
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    border-color: transparent;
  }
  .btn-#{$color}:hover {
    border-color: transparent;
  }
}

.rounded-btn {
  border: 2px solid white;
  transition: 200ms;
  border-radius: $border-radius;
  &:hover {
    background-color: white;
    a {
      color: #174984;
    }
  }
}



.btn-outline-info {
  font-weight: 600;
}

.btn-google {
  border: 2px solid $google-red;
  color: $google-red;
  transition: 200ms;
  border-radius: $border-radius;
  &:hover {
    background-color: $google-red;
    color: white;
    a {
      color: white
    }
  }
}

.btn-facebook {
  border: 2px solid $facebook;
  color: $facebook;
  transition: 200ms;
  border-radius: $border-radius;
  &:hover {
    background-color: $facebook;
    color: white;
    a {
      color: white
    }
  }
}

.delivery-btn {
  padding: 0.5rem 1.25rem;
  transition: 300ms;
  background-color: $gray-100;
  border-radius: $border-radius;
  @extend .row-flex;
  cursor: pointer;
  &:hover {
    background-color: $gray-200;
  }
  .main-delivery {
    @extend .text-truncate;
    max-width: 220px;
  }
}

.com-bar {
  @extend .row-around;
  background-color: $primary;
  padding: 0.5rem;
  color: white
}

.categories-bar {
  @extend .row-between;
  border-bottom: 1px solid $gray-200;
  padding: 0.5rem 0;
  button {
    white-space: nowrap;
    border-radius: $border-radius;
    outline: 0;
    border: 3px solid transparent;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: transparent;
    &.active {
      background-color: $primary;
      color: white;
    }
  }
}

.btn {
  line-height: 2.4;
}

.btnic {
  border-radius: $rounded-pill;;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.like-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: $rounded-pill;;
  cursor: pointer;
  text-align: center;
  font-size: 1.15rem;
  color: $primary;
  &:hover {
    background-color: $gray-200;
  }
}

.scope-btn {
  background-color: $gray-200;
  border-radius: $border-radius;
  color: $gray-700;
  transition: 300ms;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  &:hover {
    background-color: $gray-300;
  }
  cursor: pointer;
  &.selected {
    background-color: $primary;
    color: white;
  }
}

.btn-primary {
  color: white;
}

.profile-btn {
  text-transform: capitalize;
  transition: 300ms;
  border-radius: $rounded-pill;
  cursor: pointer;
  background-color: $gray-200;
  padding: 0 0.5rem 0 0.75rem;
  @extend .row-flex;
  &:hover {
    background-color: $gray-300;
  }
}