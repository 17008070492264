.order-card {
  box-shadow: 0px 4px 7px 0px #38333e1a;
  max-height: 100%;
  min-width: 360px;
  position: relative;
}

.order-header {
  @extend .row-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $gray-200;
}

.order-scrollable-body {
  max-height: calc(100vh - 66px - 74px - 2rem - 67px - 50px - 8px);
  overflow: auto;
}

.order-body {
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  border-bottom: 2px dashed $gray-200;
}

.order-address {
  padding: 0.5rem;
}

.top-border-dashed {
  border-top: 2px dashed $gray-200;
}

.status-order {
  font-size: 1.1rem;
}

.orders-container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  height: calc(100% - 66px);
  padding: 1rem;
}

.timer {
  font-size: 1.4rem;
  font-weight: 600;
}

.new-badge {
  position: absolute;
  top: 0;
  display: flex;
  left: 0;
  transform: translate(-20px, -20px);
  z-index: 1;
  background-color: $primary;
  height: 100px;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100px;
  border-radius: 100%;
  text-align: center;
  color: white;
}

.order-scope-button {
  padding: 0.75rem 1.5rem;
  background-color: $gray-200;
  font-weight: bold;
  color: $gray-600;
  &.active {
    background-color: $primarylight;
    color: white;
  }
  &.reset-scope {
    color: $primarylight;
    background-color: white;
  }
}