.notice-card {
  padding: 0.5rem;
  border: 1px solid $gray-200;
  height: 150px;
}
.card {
  box-shadow: 0 0 0px 1px rgb(0 0 0 / 11%);
  transition: 300ms;
  &.card-hover {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 1px 20px 0 rgb(0 0 0 / 0.11);
    }
  }
  .card-title {
    font-weight: 600;
    color: $gray-600;
  }
}

.product-card {
  cursor: pointer;
}

.shop-presentation {
  padding: 1.5rem 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 0 $border-radius $border-radius 0;
}

.filter-card {
  max-width: 800px;
  // margin: auto;
  @extend .card;
  border-radius: $border-radius !important;
  // transform: translateY(-50%);
  .btn {
    border-radius: 0 $border-radius $border-radius 0;
    background: linear-gradient(71deg, $red, $orange);
    border: 0;
    transition: 200ms;
    font-weight: bold;
    color: white;
    flex: 1;
    &:hover {
      background: linear-gradient(71deg, #d94d49, #d86057);
    }
  }
  .divider {
    width: 1px;
    height: 60px;
    background-color: #e9eef1;
  }
}

.card-selection-header {
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem;
  background-color: rgba($primary, 0.2);
  border-radius: $border-radius $border-radius 0 0;
}

.total-card {
  border-top: 2px solid $primary;
  margin-top: 0.5rem;
  font-weight: bold;
}

.shop-card {
  overflow: hidden;
  &:hover {
    box-shadow: $shadow, 0 3px 5px 0 rgba($primary, 0.2);
  }
}

.account-card {
  @extend .awesome-shadow;
  padding: 1.5rem 2rem;
  border-radius: $border-radius;
  font-size: 1rem;
  transition: 400ms;
  height: 100%;
  text-align: center;
  cursor: pointer;
  i {
    font-size: 1.75rem;
    color: $primary;
  }
  .account-card-title {
    font-weight: bold;
    margin-bottom: 1rem;
    color: $gray-800;
  }
  .account-card-description {
    color: $gray-600;
  }
}