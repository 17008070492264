.actuality-card {
  display: flex;
  padding: 0.5rem;
  border: 1px solid $gray-200;
  height: 200px;
  > * {
    flex: 1;
  }
  .description {
    height: 50%;
    overflow: hidden;
    white-space: pre-line;
  }
}

.actualities-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1.5rem;
}