.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
}
.pizza-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-200;
  height: 260px;
}

.product-image {
  flex: 3;
  padding: 0.75rem;
}

.new-btn-card {
  background-color: $primary;
  color: white;
  display: flex;
  font-weight: bold;
  font-size: 1.5rem;
}