$admin-body-padding: 1rem;

.admin-body-padding {
  padding: 1rem 1rem 0 1rem;
}

.admin-navbar {
  background-color: white;
  z-index: 100;
  width: 100%;
  padding: 0.25rem;
  border-bottom: 1px solid $gray-100;
  color: $gray;
  .navbar-link {
    line-height: 50px;
    height: 50px;
    text-align: center;
    background-color: $light;
    color: $gray-800;
    font-weight: 700;
    &.active {
      background-color: $info;
      color: white;
    }
  }
}

.admin-container {
  display: flex;
  .card {
    box-shadow: none;
    @extend .awesome-shadow;
    // box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.11);
  }
}

.admin-card-layout {
  padding-top: 2rem;
  .admin-card-header {
    padding-left: 4rem;
    padding-right: 2rem;
  }
  .admin-card {
    @extend .admin-body-padding;
    background-color: white;
  }
}

@include media-breakpoint-down(md) {
  .admin-body-padding {
    padding: 1rem;
  }
  .admin-card-layout {
    padding-top: 2rem;
    .admin-card-header {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .admin-card {
      padding: 2rem 1rem;
      background-color: white;
    }
  }
}

.admin-responsive-panel {
  background-color: $sidebar-color;
  animation: menu 400ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  padding: 3rem 2rem;
}

.sidebar-container {
  background-color: $sidebar-color;
  flex: 0 0 $sidebar-width;
}

.sidebar {
  padding: 3rem 2rem;
  width: 100%;
  position: sticky;
  top: 0;
}
.sidebar-title {
  padding: 0 1rem;
  margin-bottom: 4rem;
}
// @extend .border-right;
.sidebar-item {
  padding: 0.75rem 1rem;
  font-weight: 600;
  display: flex;
  color: $gray-500;
  align-items: center;
  border-radius: $border-radius;
  cursor: pointer;
  &.active, &.active:hover {
    color: $primary;
    color: white;
    background-color: $primary;
    // background-color: rgba($primary, 0.1);
  }
  .sidebar-item-icon {
    width: 40px;
    min-width: 40px;
  }
  i {
    font-size: 1.2rem;
  }
  &:hover {
    color: $primary;
    // background-color: rgba($primary, 0.1);
  }
}

.nav-label {
  background-color: $gray-200;
  padding: 1rem;
}

.admin-body {
  flex: 1 ;
  padding-bottom: 74px;
  overflow: auto;
  height: 100%;
}

.admin-title {

}

@include media-breakpoint-down(sm) {
  .admin-body {
    .card {
      border-radius: 0;
    }
    .admin-title {
      justify-content: center;
      flex-wrap: wrap;
      h2 {
        text-align: center;
        margin-right: 1rem;
      }
    }
  }
}

.select2-container {
  width: 100% !important;
}

input[type="date"] {
  max-width: 180px;
}
.gallery-block {
  width: 170px;
  height: 200px;
  margin-right: 1rem;
  border-radius: $border-radius;
}

.gallery {
  display: inline-flex;
  .gallery-image {
    position: relative;
    background-position: center;
    background-size: cover;
    @extend .gallery-block;
    .gallery-image-action {
      @extend .border;
      display: flex;
      background-color: white;
      position: absolute;
      right: 0;
      overflow: hidden;
      border-radius: 0 $border-radius;
      .close-btn {
        cursor: pointer;
        color: $secondary;
        width: 30px;
        font-size: 0.8rem;
        height: 30px;
        line-height: 30px;
        text-align: center;
        &:hover {
          background-color: $gray-200;
        }
      }
      .crown {
        cursor: pointer;
        color: $dark;
        width: 30px;
        font-size: 0.8rem;
        height: 30px;
        line-height: 30px;
        text-align: center;
        &:hover {
          background-color: $gray-200;
        }
        &.active {
          color: $warning;
        }
      }
    }
  }
  .gallery-add-btn {
    @extend .gallery-block;
    text-align: center;
    transition: 400ms;
    display: flex;
    color: $success;
    border: 2px solid $gray-200;
    font-weight: 600;
    .icon-btn {
      background-color: rgba($success, 0.6);
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin-bottom: 0.5rem;
      text-align: white;
      font-size: 1.5rem;
      border-radius: 8rem;
      color: white;
    }
    cursor: pointer;
    &:hover {
      background-color: $gray-200
    }
  }
}

.header-label {
  margin: 0 -1.25rem;
  padding: 0.5rem 1.25rem;
  background-color: $gray-100;
}

/// DASHBOARD

.round-icon {
  font-size: 1.5rem;
  border-radius: $rounded-pill;;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin: 1rem;
  &.blue {
    color: $primary;
    background-color: rgba($primary, 0.1);
  }
  &.green {
    color: $green;
    background-color: rgba($green, 0.1);
  }
  &.red {
    color: $red;
    background-color: rgba($red, 0.1);
  }
  &.orange {
    color: $orange;
    background-color: rgba($orange, 0.1);
  }
}

.chart-value {
  font-size: 1.5rem;
  font-weight: 600;
}

.select-badge {
  color: white;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
}

.circle {
  width: 25px;
  height: 25px;
  background-color: rgba($success, 0.1);
  text-align: center;
  color: $success;
  font-weight: bold;
  line-height: 25px;
}