.banner {
  height: 400px;
  background: linear-gradient(17deg, $primarylight, $primary);
  background-position: center;
  background-size: cover;
  position: relative;
}

.gradient-container {
  background: linear-gradient(17deg, $primarylight, $primary);
  position: relative;
}
@include media-breakpoint-down(md) {
  .banner {
    height: 300px;
  }
}
@include media-breakpoint-down(sm) {
  .banner {
    height: 250px;
  }
  .booking-container {
    padding: 0;
  }
  .fc-media-screen {
    margin: 0 -1.25rem;
  }
  h5 {
    text-align: center;
  }
  .site-btn {
    margin: 0 1rem;
  }
}
.section {
  padding: 90px 0;
}