.grid {
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-columns: minmax(30px, 100px);
  grid-gap: 1rem;
}

.account-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

}

.two-cols {
  display: grid;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1.5rem;
}

.product-large-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
  grid-gap: 1.5rem;
}