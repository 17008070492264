html, body, #root {
  height: 100%;
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 940px;
  }
}
@include media-breakpoint-up(md) {
  .container-fluid {
    padding: 0 2rem;
  }
}

.container-small {
  max-width: 1000px;
}

.responsible-menu {
  width: 50px;
  i {
    font-size: 1.25rem;
  }
}

.bg-login {
  background: linear-gradient(17deg, #187d95, #174984);
}

.navbar-padding {
  padding-top: 80px;
}

.home-body {
  min-height: 100vh;
  padding-top: $navbar-height;
}

.home-banner {
  height: $banner-height;
}

.footer {
  padding: 50px 0;
  background-color: $dark;
  color: white;
  .footer-title {
    color: $gray;
    font-weight: bold;
  }
  a {
    color: white;
  }
  .socials {
    font-size: 1.8rem;
    display: flex;
    .social-icon {
      display: flex;
      padding: 0.5rem;
      border-radius: $rounded-pill;;
      height: 50px;
      width: 50px;
      line-height: 50px;
      // background-color: $medium-gray;
      color: white;
      i {
        margin: auto;
      }
      &:hover {
        color: white;
      }
    }
  }
}

.search-section {
  @extend .row-center;
  position: sticky;
  top: calc(#{$navbar-height} + #{$main-action-bar-height});
}

.main-action-bar {
  height: $main-action-bar-height;
  background-color: white;
  z-index: 1;
}

.sticky-bar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-200;
  border-radius: $border-radius;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-300;
  border-radius: $border-radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray-300;
}

  .app-sidebar {
    animation: appsidebar 400ms ease-in-out;
    right: 0;
    position: fixed;
    margin-left: auto;
    z-index: 100000;
    top: 0;
    bottom: 0;
    background-color: white;
    width: 450px;
    height: 100vh;
    .app-sidebar-header {
      @extend .row-between;
      padding: 1.5rem;
      color: white;
      font-size: 1.5rem;
      background-color: $dark;
    }
  }

  .main-action-bar {
    @extend .row-center;
    padding: 1.5rem;
    z-index: 1;
    position: sticky;
    top: $navbar-height;
    border-bottom: 1px solid $gray-200;
    background-color: white;
  }

  .gift-tabs {
    margin-top: 1rem;
    .gift-tabs-item {
      padding: 0.75rem 1.5rem;
      color: #4c5b71;
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      border-radius: 4px;
      font-size: 2rem;
      box-shadow: 0px 4px 15px -4px #4c5b715c;
      height: 180px;
    }
  }